import HeaderCom from '../../../components/Header.vue';

export default {
  name: 'personal',
  components: {
    HeaderCom
  },
  data() {
    return {
      infoForm: {
        sex: '0'
      },
      infoRule: {
        photo: [
          { required: true, message: '本人证件照为必填', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '姓名为必填', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '手机号为必填', trigger: 'blur' }
        ]
      },
      imgName: '',
      visible: false,
      uploadList: [],
    }
  },
  mounted() {
    this.uploadList = this.$refs.upload.fileList;
    this.getUserInfo();
  },
  methods: {
    // 获取个人信息
    getUserInfo() {
      this.$http.getUserInfo().then(data=>{
        if(data.code == '200') {

        }else{
          this.$Message.error(data.msg);
        }
      }).catch(error=>{

      })
    },
    handleView(name) {
      this.imgName = name;
      this.visible = true;
    },
    handleRemove(file) {
      const fileList = this.$refs.upload.fileList;
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
    },
    handleSuccess(res, file) {
      file.url = 'https://o5wwk8baw.qnssl.com/7eb99afb9d5f317c912f08b5212fd69a/avatar';
      file.name = '7eb99afb9d5f317c912f08b5212fd69a';
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$Message.warning({
          background: true,
          content: "上传图片大小不能超过 1MB！"
        });
      }
      return isLt2M;
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          
        } else {
          return false;
        }
      })
    },
    cancel() {
      
    }
  }
}