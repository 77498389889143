<template>
  <div class="header">
    <div class="logo fl">
      <img src="../assets/images/logo.png" alt />
      <!-- <h3 class="title">水利工程能力测试</h3> -->
    </div>
    <Menu class="fl" mode="horizontal" :theme="theme" active-name="1">
      <MenuItem name="1" to="/home">
      <Icon custom="iconfont icon-renyuanbaoming" size="20" />报名中心
      </MenuItem>
      <!-- <MenuItem name="2" to="/applyPay">
      <Icon custom="iconfont icon-jiaofeishezhi" size="20" />报名缴费
      </MenuItem> -->
      <!-- <MenuItem name="3" to="/print">
      <Icon custom="iconfont icon-dayin" size="20" />打印准考证
      </MenuItem>
      <MenuItem name="4" to="/performance">
      <Icon custom="iconfont icon-chengji" size="20" />成绩查询
      </MenuItem>
      <MenuItem name="5" to="/personal">
      <Icon custom="iconfont icon-gerenxinxi" size="20" />个人信息
      </MenuItem> -->
    </Menu>
    <Menu class="fr" mode="horizontal" :theme="theme" active-name="2" @on-select='selectChange'>
      <Submenu name="6">
        <template slot="title" class="demo-avatar">
          <Avatar icon="ios-person" />
          <span class="name">{{userInfo.name}}</span>
        </template>
        <!-- <MenuGroup>
          <MenuItem name="update">修改个人信息</MenuItem>
        </MenuGroup> -->
        <MenuGroup>
          <MenuItem name="logout">退出登录</MenuItem>
        </MenuGroup>
      </Submenu>
    </Menu>
    <!-- 修改个人信息 -->
    <Modal title="修改个人信息" v-model="updateModal" footer-hide width="30%">
      <Form class="addUser" ref="updateForm" :model="updateForm" :rules='updateRule' :label-width="120" label-colon>
        <FormItem label="姓名" prop="name">
          <Input v-model="updateForm.name" placeholder="请输入姓名" clearable></Input>
        </FormItem>
        <FormItem label="性别" prop="gender">
          <RadioGroup v-model="updateForm.gender">
            <Radio class="sexRedio" v-for='(item,i) in genderList' :key="i" :label='item.dictValue' :value='item.dictLabel'></Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="身份证号" prop="idNumber">
          <Input v-model="updateForm.idNumber" placeholder="请输入身份证号" clearable></Input>
        </FormItem>
        <FormItem class="phone" label="联系电话" prop="phoneNumber">
          <Input v-model="updateForm.phoneNumber" placeholder="请输入手机号" clearable></Input>
        </FormItem>
        <FormItem class="bot">
          <Button type="primary" @click="updateInfo('updateForm')">确定</Button>
          <Button type="primary" ghost @click="updateModal = false">取消</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      theme: "primary",
      userInfo: {},
      updateModal: false,
      genderList: [],
      updateForm: {
        name: "",
        gender: "男",
        idNumber: "",
        phoneNumber: "",
      },
      updateRule: {},
    };
  },
  mounted() {
    // this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.biz_gender();
    this.getUserInfo();
  },
  methods: {
    // 性别
    biz_gender() {
      this.$comHttp
        .biz_gender()
        .then((data) => {
          if (data.code == "200") {
            this.genderList = data.data;
          } else {
            this.$Message.error(data.msg);
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    selectChange(name) {
      if (name == "logout") {
        this.logout();
      } else {
        this.updateModal = true;
        // this.$refs["updateForm"].resetFields();
      }
    },
    // 退出登录
    logout() {
      this.$Modal.confirm({
        title: "确定要退出登录吗?",
        onOk: () => {
          this.$http
            .logout()
            .then((data) => {
              // if (data.code == "200") {

              // } else {
              //   // this.$Message.error(data.msg);
              // }
              console.log(data.msg);
              sessionStorage.removeItem("token");
              this.$Message.success("退出成功!");
              setTimeout(() => {
                this.$router.push({
                  path: "/login",
                });
              }, 1000);
            })
            .catch((error) => {});
        },
        cancelText: "取消",
      });
    },
    getUserInfo() {
      this.$http
        .getMyLast()
        .then((data) => {
          if (data.code == "200") {
            this.userInfo = data.data;
          } else {
            this.$Message.error(data.msg);
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    updateInfo(name) {
      var params = {
        // oldPassword: this.updateForm.oldPassword,
        // newPassword: this.updateForm.newPassword,
      };
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$http
            .updatePwd(params)
            .then((data) => {
              if (data.code == "200") {
                this.$Message.success("个人信息修改成功!");
                setTimeout(() => {
                  this.$router.push({
                    path: "/login",
                  });
                }, 500);
              } else {
                this.$Message.error(data.msg);
              }
            })
            .catch((error) => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/icon_custom/iconfont.css");

/deep/.ivu-menu-item-group-title {
  display: none;
}
/deep/.ivu-menu-item {
  font-size: 16px;
}
/deep/.ivu-menu-submenu-title span > i,
.ivu-menu-submenu-title > i {
  margin-right: 0;
}
// /deep/.ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item{
//   font-size: 16px !important ;
// }
.header {
  width: 100%;
  height: 60px;
  background: #2d8cf0;
  padding: 0 20px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
}
.logo {
  width: 230px;
  height: 100%;
  position: relative;
  img {
    max-width: 90%;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.name {
  margin-left: 10px;
  margin-right: 5px;
}
.title {
  font-size: 24px;
  color: white;
  line-height: 60px;
}
</style>